



























































































import {Component, Vue, Watch} from 'vue-property-decorator'
import {validationMixin} from 'vuelidate'
import {maxLength, minLength, minValue, required, ValidationRule} from 'vuelidate/lib/validators'
import {Validations} from 'vuelidate-property-decorators'
import VoucherTextInput from '@/app/ui/views/Voucher/components/VoucherTextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import controller from '@/app/ui/controllers/VoucherConfigSKUController'

interface validationInterface {
  form: {
    skuCode: {
      required: () => ValidationRule,
      minLength: ValidationRule
    },
    voucherName: {
      required: () => ValidationRule,
      minLength: ValidationRule,
      maxLength: ValidationRule,
    },
    amount: {
      required: () => ValidationRule,
      minValue: ValidationRule,
    },
  }
}

@Component({
  mixins: [validationMixin],
  components: {Modal, ModalAction, Button, VoucherTextInput}
})
export default class VoucherAndSKUCreate extends Vue {
  controller = controller
  maxCount = 30

  form: {
    skuCode: string,
    voucherName: string,
    amount: number,
  } = {
    skuCode: '',
    voucherName: '',
    amount: 0,
  }

  showModalConfirm = false
  showModalSuccess = false
  modalActionData = {
    title: '',
    description: '',
    textPrimary: '',
    textSecondary: '',
  }

  @Validations()
  validations(): validationInterface {
    return {
      form: {
        skuCode: {
          required,
          minLength: minLength(1),
        },
        voucherName: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(this.maxCount),
        },
        amount: {
          required,
          minValue: minValue(1),
        },
      }
    }
  }

  actionShowConfirm(): void {
    this.showModalConfirm = true
    this.modalActionData = {
      title: `Are you sure want to create new SKU Voucher : ${this.form.skuCode} - ${this.form.voucherName} ?`,
      description: '',
      textPrimary: 'Yes, Create',
      textSecondary: 'Check Again',
    }
  }

  doActionModal(): void {
    this.showModalConfirm = false
    controller.create(this.form)
  }

  @Watch('controller.isSave')
  onIsSaveChange(): void {
    if (this.controller.isSave) {
      this.showModalSuccess = true
    }
  }

  onCloseSuccessModal(): void {
    this.$router.push('/voucher/voucher-config?tab=voucher-and-sku')
  }
}
